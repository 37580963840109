<template>
  <section>
    <div class="content-header">
      <h2>Why do women opt out? Sense of belonging and women's representation in mathematics </h2>
    </div>
    <div class="content-wrapper">
      <p>There have been many studies done to explore the question of why women are underrepresented in math studies. </p>
      <p><a href="https://psycnet.apa.org/doiLanding?doi=10.1037%2Fa0026659" target="_blank">This study</a> looked directly at sense of belonging as a factor and found that the message that math ability is a fixed trait and the stereotype that women have less of this ability than men worked together to erode women’s sense of belonging in math. </p>
      <p>Their lowered sense of belonging, in turn, mediated women’s desire to pursue math in the future and their math grades. </p>
      <p>Interestingly, the message that math ability could be acquired protected women from negative stereotypes, allowing them to maintain a high sense of belonging in math and the intention to pursue math in the future. </p>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
